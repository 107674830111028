<template>
  <header ref="Logo" id="titlebar" >
		<b-img src="../assets/flying-freelancers-logo.svg" alt="Flying Freelancers" id="logo" class="logo"  />
    <b-spinner v-if="loading" small></b-spinner>
		<Navigation />
		<i class="fa-solid fa-user"></i>

  </header>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Navigation from './Navigation'

export default {
	name: 'title-bar',
	data() {
		return {
		}
	},
	computed: {
    ...mapGetters([
      'is_logged_in',

    ]),
		...mapState([
      'server_online',
      'loading'
		])
  },
	methods: {
    on_content_changed(event, date) {
			this.$store.dispatch('set_week_comment', {date: new Date(date.getTime()), text: event.target.innerText })
    },
    comment(date) {
			if (this.week_comments[date.getTime()]) {
				return this.week_comments[date.getTime()]
			}
			return {
				date: date,
				text: ""
			}
		},
	},
	components: { Navigation },
	mounted() {}
}
</script>
