<template>
	<div id="app" class="wrapper">
		<TitleBar />
		<!-- LOGIN -->
		<div class="container" v-if="!is_logged_in">
			<div class="row justify-content-center">
				<Login />
			</div>
		</div>
		<main v-else class="home">
			<router-view></router-view>
		</main>
	</div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import Login from './components/Login'
import TitleBar from './components/TitleBar'

export default {
	name: 'app',
	data() {
		return {
			resize_timeout: null,
		}
	},

	computed: {
		...mapGetters([
			'is_logged_in',

		]),
		...mapState([
			'loading',
		])
	},
	mounted: function () {
		window.addEventListener('resize', this.handleResize)
		this.initialize()
	},
	beforeDestroy: function () {
		window.removeEventListener('resize', this.handleResize)
	},
	methods: {
		...mapActions([
			'initialize',
			'set_date',
		]),

		handleResize () {
			clearTimeout(this.resize_timeout)
			this.resize_timeout = setTimeout(this.setNumColumns, 100);
		},
	},
	components: { Login, TitleBar }
}
</script>

