<template>
  <b-container fluid>
    <h1>Portal {{ portal.name }}</h1>
    <PortalNavigations />
    <h1>License</h1>
    <select name="licenses" class="form-control admins" v-model="settings.license">
      <option value="-1">Kies pakket</option>
      <option value="1">Pakket 1: Flex</option>
      <option value="2">Pakket 2: Growth</option>
      <option value="3">Pakket 3: Pro</option>
    </select>    
    <b-row>
      <b-col cols="3"></b-col>
      <b-col>    
        <b-form-checkbox  v-model="settings.CountZZPers">Tel ZZPers op factuur</b-form-checkbox>
        </b-col>
        </b-row>
        <b-row>
      <b-col cols="3"></b-col>
      <b-col>        
        <b-form-checkbox  v-model="settings.CountSigners">Tel Tekenbevoegde op factuur</b-form-checkbox>
        </b-col>
        </b-row>
    <b-row>
      <b-col cols="3"></b-col>
      <b-col>
        <b-form-checkbox  v-model="settings.CountModules">Tel Modules op factuur</b-form-checkbox>
      </b-col>
    </b-row>    
    <b-row>
      <b-col cols="3">Korting</b-col>
      <b-col><b-form-input type="number" step='0.01'  v-model="settings.LicenseDiscount"/></b-col>
    </b-row>    
    <b-row>
      <b-col cols="3">Email voor gebruiksfactuur</b-col>
      <b-col>
        <b-form-input type="email" v-model="settings.invoiceEmail"/>
      </b-col>
    </b-row>       
    <h2>Factuuradres informatie afnemer</h2>
    <div id="app">
      <b-form-textarea v-model="settings.adminadres" />
    </div>  
    <h2>Afleveradres informatie afnemer</h2>
    <div id="app">
      <b-form-textarea v-model="settings.adminadresaflever" />
    </div>

    <h1>Modules</h1>

    <h2>Navigatie</h2>

<b-form-checkbox  v-model="settings.ShowInvoicePurchaseNav">{{ $t("modules.ShowInvoicePurchaseNav") }}</b-form-checkbox>
<b-form-checkbox  v-model="settings.ShowInvoiceSaleNav">{{ $t("modules.ShowInvoiceSaleNav") }}</b-form-checkbox>
<b-form-checkbox  v-model="settings.ShowPlanningNav">{{ $t("modules.ShowPlanningNav") }}</b-form-checkbox>
<b-form-checkbox  v-model="settings.ShowPlanningPerWeekNav">{{ $t("modules.ShowPlanningPerWeekNav") }}</b-form-checkbox>
<b-form-checkbox  v-model="settings.ShowSearchNav">{{ $t("modules.ShowSearchNav") }}</b-form-checkbox>
<b-form-checkbox  v-model="settings.ShowTodoNav">{{ $t("modules.ShowTodoNav") }}</b-form-checkbox>

<h2>Knoppen</h2>

<b-form-checkbox  v-model="settings.ShowEmailButton">{{ $t("modules.ShowEmailButton") }}</b-form-checkbox>
<b-form-checkbox  v-model="settings.ShowInvoicePurchaseButton">{{ $t("modules.ShowInvoicePurchaseButton") }}</b-form-checkbox>
<b-form-checkbox  v-model="settings.ShowInvoiceSaleButton"> {{ $t("modules.ShowInvoiceSaleButton") }}</b-form-checkbox>
<b-form-checkbox  v-model="settings.EnableBlacklist">{{ $t("modules.EnableBlacklist") }}</b-form-checkbox>

<h2>Opties</h2>
<b-form-checkbox  v-model="settings.InvoiceByHourcode">{{ $t("modules.InvoiceByHourcode") }}</b-form-checkbox>
<b-form-checkbox  v-model="settings.AddCreditInvoice">{{ $t("modules.AddCreditInvoice") }}</b-form-checkbox>
<b-form-checkbox  v-model="settings.PMHistory">{{ $t("modules.PMHistory") }}</b-form-checkbox>
<b-form-checkbox  v-model="settings.NewChat">{{ $t("modules.NewChat") }}</b-form-checkbox>
<b-form-checkbox  v-model="settings.SendInvoiceOnChecked">{{ $t("modules.SendInvoiceOnChecked") }}</b-form-checkbox>
<b-form-checkbox  v-model="settings.SendInvoiceSaleOnChecked">{{ $t("modules.SendInvoiceSaleOnChecked") }}</b-form-checkbox>
<b-form-checkbox  v-model="settings.EnableHourPlanning">{{ $t("modules.EnableHourPlanning") }}</b-form-checkbox>
<b-form-checkbox  v-model="settings.EnableVacationPlanning">{{ $t("modules.EnableVacationPlanning") }}</b-form-checkbox>
<b-form-checkbox  v-model="settings.EnableUsageInvoices">{{ $t("modules.EnableUsageInvoices") }}</b-form-checkbox>
<b-form-checkbox  v-model="settings.EnableEmployees">{{ $t("modules.EnableEmployees") }}</b-form-checkbox>
<b-form-checkbox  v-model="settings.EnableNewsletter">{{ $t("modules.EnableNewsletter") }}</b-form-checkbox>
<b-form-checkbox  v-model="settings.RegisterWorkhours">{{ $t("modules.RegisterWorkhours") }}</b-form-checkbox>
<b-form-checkbox  v-model="settings.SignWorkhours">{{ $t("modules.SignWorkhours") }}</b-form-checkbox>
<b-form-checkbox  v-model="settings.EnableWorkTimer">{{ $t("modules.EnableWorkTimer") }}</b-form-checkbox>
<b-form-checkbox  v-model="settings.EnableScheduler">{{ $t("modules.EnableSchedule") }}</b-form-checkbox>

    <!-- Save button -->
    <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="save" /> {{ $t("buttons.savepages") }}
    </div>       

  </b-container>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapState } from 'vuex'
import PortalNavigations from './PortalNavigations.vue';

var CryptoJS = require("crypto-js");
function SLemesPCrypt() {
  this.key = 'DontmesswithMyData!';
  this.JsonFormatter = {
    stringify: function (cipherParams) {
      // create json object with ciphertext
      var jsonObj = {
        ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)
      };
      // optionally add iv and salt
      if (cipherParams.iv) {
        jsonObj.iv = cipherParams.iv.toString(CryptoJS.enc.Base64)
      }
      if (cipherParams.salt) {
        jsonObj.s = cipherParams.salt.toString(CryptoJS.enc.Base64)
      }
      // stringify json object
      return JSON.stringify(jsonObj);
    },
    parse: function (jsonStr) {
      // parse json string
      var jsonObj = JSON.parse(jsonStr);
      // extract ciphertext from json object, and create cipher params object
      var cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Base64.parse(jsonObj.ct)
      });
      // optionally extract iv and salt
      if (jsonObj.iv) {
        cipherParams.iv = CryptoJS.enc.Hex.parse(CryptoJS.enc.Base64.parse(jsonObj.iv).toString())
      }
      if (jsonObj.s) {
        cipherParams.salt = CryptoJS.enc.Hex.parse(CryptoJS.enc.Base64.parse(jsonObj.s).toString())

      }
      return cipherParams;
    }
  };
  this.encrypt = function (data) {
    var iv = CryptoJS.lib.WordArray.random(128 / 8);
    var salt = CryptoJS.lib.WordArray.random(32 / 8);
    var localkey = CryptoJS.PBKDF2(this.key, salt, { keySize: 256 / 32, iterations: 1 });
    var encrypted = CryptoJS.AES.encrypt(data, localkey, { padding: CryptoJS.pad.ZeroPadding, format: this.JsonFormatter, iv: iv });
    encrypted = this.JsonFormatter.parse("{\"ct\":\"" + encrypted.ciphertext.toString(CryptoJS.enc.Base64) + "\",\"iv\":\"" + encrypted.iv.toString(CryptoJS.enc.Base64) + "\",\"s\":\"" + salt.toString(CryptoJS.enc.Base64) + "\"}");
    return btoa(this.JsonFormatter.stringify(encrypted));
  };
  this.decrypt = function (data) {
    var encrypted = this.JsonFormatter.parse(Buffer.from(data, 'base64'));
    var salt = CryptoJS.enc.Hex.parse(encrypted.salt.toString());
    var key = CryptoJS.PBKDF2(this.key, salt, { keySize: 256 / 32, iterations: 1 });
    var decrypted = CryptoJS.AES.decrypt(encrypted, key, { padding: CryptoJS.pad.ZeroPadding, format: this.JsonFormatter, iv: CryptoJS.enc.Hex.parse(encrypted.iv.toString()) });
    return decrypted.toString(CryptoJS.enc.Utf8);
  };

}

export default {
  name: 'portal-detail',
  data() {
    return {
      portal_id:null,
      settings:{},
    }
  },
  async mounted() {
     this.portal_id = Number(this.$route.params.id)
     this.$nextTick(async () => {
      await axios.get(this.portal.url+"api/public_settings")
     .then(({data}) => {
            this.settings = data
          })

      })

  },
  computed: {
    portal: {
      get() {
        return this.portal_by_id(this.$route.params.id)
      },
    },

    ...mapGetters([
      'portal_by_id',
      'current_portal'
    ]),
    ...mapState([
        'portals',
    ])
  },
  methods:{
    save_settings() {

      try {
        let new_settings = {
            license: this.settings.license?this.settings.license:"1",
            adminadres :  this.settings.adminadres?this.settings.adminadres:"",
            adminadresaflever: this.settings.adminadresaflever?this.settings.adminadresaflever:"",
            CountZZPers: this.settings.CountZZPers,
            CountSigners: this.settings.CountSigners,
            CountModules: this.settings.CountModules,
            LicenseDiscount: this.settings.LicenseDiscount?this.settings.LicenseDiscount:0,
            invoiceEmail:this.settings.invoiceEmail?this.settings.invoiceEmail:"",
            ShowInvoicePurchaseNav: this.settings.ShowInvoicePurchaseNav?this.settings.ShowInvoicePurchaseNav:false,
            ShowInvoiceSaleNav: this.settings.ShowInvoiceSaleNav?this.settings.ShowInvoiceSaleNav:false,
            ShowPlanningNav: this.settings.ShowPlanningNav?this.settings.ShowPlanningNav:false,
            ShowPlanningPerWeekNav: this.settings.ShowPlanningPerWeekNav?this.settings.ShowPlanningPerWeekNav:false,
            ShowSearchNav:this.settings.ShowSearchNav?this.settings.ShowSearchNav:false,
            ShowTodoNav:this.settings.ShowTodoNav?this.settings.ShowTodoNav:false,
            ShowEmailButton:this.settings.ShowEmailButton?this.settings.ShowEmailButton:false,
            ShowInvoicePurchaseButton:this.settings.ShowInvoicePurchaseButton?this.settings.ShowInvoicePurchaseButton:false,
            ShowInvoiceSaleButton:this.settings.ShowInvoiceSaleButton?this.settings.ShowInvoiceSaleButton:false,
            InvoiceByHourcode:this.settings.InvoiceByHourcode?this.settings.InvoiceByHourcode:false,
            AddCreditInvoice:this.settings.AddCreditInvoice?this.settings.AddCreditInvoice:false,
            PMHistory: this.settings.PMHistory?this.settings.PMHistory:false,
            NewChat:this.settings.NewChat?this.settings.NewChat:false,
            SendInvoiceOnChecked:this.settings.SendInvoiceOnChecked?this.settings.SendInvoiceOnChecked:false,
            SendInvoiceSaleOnChecked:this.settings.SendInvoiceSaleOnChecked?this.settings.SendInvoiceSaleOnChecked:false,
            EnableBlacklist:this.settings.EnableBlacklist?this.settings.EnableBlacklist:false,
            EnableHourPlanning:this.settings.EnableHourPlanning?this.settings.EnableHourPlanning:false,
            EnableVacationPlanning:this.settings.EnableVacationPlanning?this.settings.EnableVacationPlanning:false,
            EnableUsageInvoices:this.settings.EnableUsageInvoices?this.settings.EnableUsageInvoices:false,
            EnableEmployees:this.settings.EnableEmployees?this.settings.EnableEmployees:false,
            EnableNewsletter:this.settings.EnableNewsletter?this.settings.EnableNewsletter:false,
            RegisterWorkhours:this.settings.RegisterWorkhours?this.settings.RegisterWorkhours:false,
            SignWorkhours:this.settings.SignWorkhours?this.settings.SignWorkhours:false,
            EnableWorkTimer: this.settings.EnableWorkTimer?this.settings.EnableWorkTimer:false,
            EnableScheduler:this.settings.EnableScheduler?this.settings.EnableScheduler:false,


          }  
          var values = ""
          for (const key in new_settings) {
            // console.log(key, new_settings[key])
            values+=new_settings[key].toString()
          }


          var secret = new SLemesPCrypt();
          var encrypted = secret.encrypt(values);   
          // let based = Buffer.from(JSON.stringify(new_settings) , 'utf8')
          axios.post('/api/portal_settings',{
            encrypted:encrypted,
            new_settings:new_settings,
            portal:this.portal.url
          })
          // console.log( "based", based.toString("base64"))
          // axios.patch(this.portal.url+'api/store_settings',
          //   { encrypted:encrypted, data:based.toString("base64")},
          // )
          // .then((response) => {
          //   console.log(response)
          // })
      } catch (err) {
        console.log(err)
      }
    },


  },
  components:{PortalNavigations}
}
    
</script>