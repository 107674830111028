<template>
	<div class="popup">

        <div class="popup-header">
            <div v-if="portal.id > 0">
                {{portal.name}}          

            </div>
            <div v-else>
                Nieuwe portal

            </div>
            <div v-if="portal.id == 0" class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
            <div v-else class="popup-close" @click="save_and_close">
                <font-awesome-icon icon="times" />
            </div>
        </div>

        <div class="popup-body">
            <b-container>
                <form class="form">
                    <!-- portalname -->
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">Name</label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" v-model="portal.name">
                        </div>
                    </div>
                    <!-- portalname -->
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">Login code app</label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" v-model="portal.code">
                        </div>
                    </div>
                    <!-- portalname -->
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">Portal url</label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" v-model="portal.url">
                        </div>
                    </div>
                    <!-- portalname -->
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">extraoptions</label>
                        </div>
                        <div class="col">
                            <b-form-checkbox  v-model="portal.extraoptions"  unchecked-value="0" value="1"/>
                        </div>
                    </div>
                    <!-- portalname -->
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">debnum</label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" v-model="portal.debnum">
                        </div>
                    </div>
                    <!-- portalname -->
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">send invoices</label>
                        </div>
                        <div class="col">
                            <b-form-checkbox  v-model="portal.invoices" unchecked-value="0" value="1"/>
                        </div>
                    </div>
                    <!-- portalname -->
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">dashboard</label>
                        </div>
                        <div class="col">
                            <b-form-checkbox  v-model="portal.dashboard"  unchecked-value="0" value="1"/>
                        </div>
                    </div>                    
                    
                    <!-- portalname -->
                    <div class="form-row">
                        <div class="col-3">
                            <label for="">PM2 process</label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" v-model="portal.pm2">
                        </div>
                    </div>                    
                    

                </form>
            </b-container>
        </div>

        <div class="popup-footer">
            <div v-if="portal.id == 0" class="btn btn-primary" @click="add_portal(portal); $emit('close')">Toevoegen</div>
            <div v-else @click="save_and_close" class="btn btn-primary">Opslaan & Sluiten</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
    name: 'portal-modal',
    data() {
        return {
            new_portal: {id:0}
        }
    },
    props: {
      portal_id: Number
    },
    computed: {
        portal() {
            console.log('modal',this.portal_id)
            return this.portal_id > 0 ? this.portal_by_id(this.portal_id) : this.new_portal
        },
        ...mapGetters([
            'portal_by_id',

        ]),
        ...mapState([
            'portals',
            'settings',
        ])
    },
    methods: {
        save_and_close() {
            this.save_portal(this.portal.id)
            this.$emit('close')
        },
        ...mapActions([
            'save_portal',
            'add_portal'
        ]),

    }
}
</script>