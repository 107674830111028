<template>
  <b-container fluid>
    <h1>Portal Statistics {{ portal.name }}</h1>
    <PortalNavigations />

    <LineChartGenerator v-if="stats.perweek"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height" />

  </b-container>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapState } from 'vuex'
import PortalNavigations from './PortalNavigations.vue';

// https://vue-chartjs.org/examples/#vue-3-charts
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement)

export default {
  name: 'portal-stats',
  data() {
    return {
      portal_id:null,
      stats:{},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    // plugins: {
    //   type: Array,
    //   default: () => []
    // }
  },  
  async mounted() {
     this.portal_id = Number(this.$route.params.id)
     this.$nextTick(async () => {
      await axios.get(this.portal.url+"api/usage")
     .then(({data}) => {
            this.stats = data
          })

      })
    

  },
  computed: {
    portal: {
      get() {
        return this.portal_by_id(this.$route.params.id)
      },
    },
    chartData() {
        let labels =[]
        let zzpers = []
        let signers = []
        if(this.stats.perweek) {
        this.stats.perweek.forEach(week => {
          labels.push(week.startdate)
          zzpers.push(week.usercount)
          signers.push(week.signerscount)
        })
      }
          
        return {
        labels: labels,
        datasets: [
          {
            label: 'ZZPers',
            backgroundColor: '#f87979',
            data: zzpers
          },{
            label: 'Signers',
            backgroundColor: '#74aaaa',
            data: signers            
          }
        ]}
    },    

    ...mapGetters([
      'portal_by_id',
    ]),
    ...mapState([
        'portals',
    ])
  },
  methods:{

  },
  components: { LineChartGenerator ,PortalNavigations}


}
    
</script>