<template>
  <b-container fluid>
    <b-row>
          <b-col cols="8">
              <h1>Invoices</h1>
          </b-col>
          <b-col>
              <div @click="togenerate()" class="btn btn-primary"><font-awesome-icon icon="plus" /> Add Invoice</div>
          </b-col>
      </b-row>
    <b-table :items="invoices" :fields="fields">
      <template #cell(id)="row">
        {{ portal_by_id(row.item.portal_id).name }}
        </template>
        <template #cell(totalcosts)="row">
          {{ row.item.totalcosts.toLocaleString('nl-NL', { minimumFractionDigits: 2, style: "currency", currency: "EUR" })}}
        </template>
        <template #cell(actions)="row">
          <a href="#" @click="openinvoice(row.item)">  <font-awesome-icon icon="eye" /></a>
        </template>

    </b-table>
  </b-container>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'


export default {
  name: 'invoices',
  data() {
      return {
        interval:null,
        refresh:0
      }
  },
  computed: {

      ...mapGetters([
          'createAny',
          'readAny',
          'updateAny',
          'deleteAny',
          'current_portal',
          'portal_by_id'
      ]),
      ...mapState([
          'invoices',
          'settings'
      ]),
      fields() {
      return [
        { key: 'id', label: "Portal", sortable: true, sortDirection: 'desc' },
        { key: 'invoicenumber', label: "Factuurnummer" },
        { key: 'invoicedate', label: "Datum" },
        { key: 'totalcosts', label: "Bedrag" },
        { key: 'actions'}
      ]
    },      
  },
  methods:{
      ...mapActions([
          'delete_portal',
          'save_portal'
      ]),
      openinvoice(item){
        let routeUrl = 'https://admin.flyingfreelancers.nl/api/media_invoices/ffinvoice-' + item.debnum + "-" + item.invoicenumber + ".pdf"
        window.open(routeUrl, '_blank')

      },
      togenerate(){
        this.$router.push({ path: '/geninvoice/' });

      }
    
  },
}
</script>
