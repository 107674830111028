<template>
  <b-container fluid>
    <h1>Module Kosten </h1> 
    <h2>Navigatie</h2>
    <div class="form-row">
      <div class="col-3"><label for="">{{ $t("modules.ShowInvoicePurchaseNav") }}</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.ShowInvoicePurchaseNavCosts"/></div>
    </div>
    <div class="form-row">
      <div class="col-3"><label for=""> {{ $t("modules.ShowInvoiceSaleNav") }}</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.ShowInvoiceSaleNavCosts"/></div>
    </div>
    <div class="form-row">
      <div class="col-3"><label for="">{{ $t("modules.ShowPlanningNav") }}</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.ShowPlanningNavCosts"/></div>
    </div>
    <div class="form-row">
      <div class="col-3"><label for="">{{ $t("modules.ShowPlanningPerWeekNav") }}</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.ShowPlanningPerWeekNavCosts"/></div>
    </div>    
    <div class="form-row">
      <div class="col-3"><label for="">{{ $t("modules.ShowSearchNav") }}</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.ShowSearchNavCosts"/></div>
    </div>        
    <div class="form-row">
      <div class="col-3"><label for=""> {{ $t("titles.todo") }}</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.ShowTodoNavCosts"/></div>
    </div>        

    <h2>Knoppen</h2>
    <div class="form-row">
      <div class="col-3"><label for="">{{ $t("modules.ShowEmailButton") }}</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.ShowEmailButtonCosts"/></div>
    </div>        
    <div class="form-row">
      <div class="col-3"><label for="">{{ $t("modules.ShowInvoicePurchaseButton") }}</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.ShowInvoicePurchaseButtonCosts"/></div>
    </div>        
    <div class="form-row">
      <div class="col-3"><label for="">{{ $t("modules.ShowInvoiceSaleButton") }}</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.ShowInvoiceSaleButtonCosts"/></div>
    </div>        
    <div class="form-row">
      <div class="col-3"><label for="">{{ $t("modules.EnableBlacklist") }}</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.EnableBlacklistCosts"/></div>
    </div>        

    <h2>Opties</h2>
    <div class="form-row">
      <div class="col-3"><label for="">{{ $t("modules.InvoiceByHourcode") }}</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.InvoiceByHourcodeCosts"/></div>
    </div>     
    <div class="form-row">
      <div class="col-3"><label for=""> {{ $t("modules.AddCreditInvoice") }}</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.AddCreditInvoiceCosts"/></div>
    </div>     
    <div class="form-row">
      <div class="col-3"><label for="">{{ $t("modules.PMHistory") }}</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.PMHistoryCosts"/></div>
    </div>     
    <div class="form-row">
      <div class="col-3"><label for=""> {{ $t("modules.NewChat") }}</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.NewChatCosts"/></div>
    </div>     
    <div class="form-row">
      <div class="col-3"><label for=""> {{ $t("modules.SendInvoiceOnChecked") }}</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.SendInvoiceOnCheckedCosts"/></div>
    </div>     
    <div class="form-row">
      <div class="col-3"><label for=""> {{ $t("modules.SendInvoiceSaleOnChecked") }}</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.SendInvoiceSaleOnCheckedCosts"/></div>
    </div>     
    <div class="form-row">
      <div class="col-3"><label for=""> {{ $t("modules.EnableHourPlanning") }}</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.EnableHourPlanningCosts"/></div>
    </div>     
    <div class="form-row">
      <div class="col-3"><label for=""> {{ $t("modules.EnableVacationPlanning") }}</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.EnableVacationPlanningCosts"/></div>
    </div>     
    <div class="form-row">
      <div class="col-3"><label for="">{{ $t("modules.EnableUsageInvoices") }}</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.EnableUsageInvoicesCosts"/></div>
    </div>     
    <div class="form-row">
      <div class="col-3"><label for=""> {{ $t("modules.EnableEmployees") }}</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.EnableEmployeesCosts"/></div>
    </div>     
    <div class="form-row">
      <div class="col-3"><label for=""> {{ $t("modules.EnableNewsletter") }}</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.EnableNewsletterCosts"/></div>
    </div>     
    <div class="form-row">
      <div class="col-3"><label for=""> {{ $t("modules.RegisterWorkhours") }}</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.RegisterWorkhoursCosts"/></div>
    </div>     
    <div class="form-row">
      <div class="col-3"><label for=""> {{ $t("modules.SignWorkhours") }}</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.SignWorkhoursCosts"/></div>
    </div>     
    <div class="form-row">
      <div class="col-3"><label for=""> {{ $t("modules.EnableWorkTimer") }}</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.EnableWorkTimerCosts"/></div>
    </div>     
    <!-- Save button -->
    <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="save" /> {{ $t("buttons.savepages") }}
    </div>       

  </b-container>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'module-costs',
  data() {
    return {
      portal_id:null,
    }
  },
  async mounted() {
       
  },
  computed: {
    portal: {
      get() {
        return this.portal_by_id(this.$route.params.id)
      },
    },

    ...mapGetters([
      'portal_by_id',
      'current_portal'
    ]),
    ...mapState([
        'portals',
        'settings'

    ])
  },
  methods:{
    save_settings() {
      try {
        axios.patch('/api/settings/',
          {
            ShowInvoicePurchaseNavCosts:this.settings.ShowInvoicePurchaseNavCosts,
            ShowInvoiceSaleNavCosts:this.settings.ShowInvoiceSaleNavCosts,
            ShowPlanningNavCosts:this.settings.ShowPlanningNavCosts,
            ShowPlanningPerWeekNavCosts:this.settings.ShowPlanningPerWeekNavCosts,
            ShowSearchNavCosts:this.settings.ShowSearchNavCosts,
            ShowTodoNavCosts:this.settings.ShowTodoNavCosts,
            ShowEmailButtonCosts:this.settings.ShowEmailButtonCosts,
            ShowInvoicePurchaseButtonCosts:this.settings.ShowInvoicePurchaseButtonCosts,
            ShowInvoiceSaleButtonCosts:this.settings.ShowInvoiceSaleButtonCosts,
            EnableBlacklistCosts:this.settings.EnableBlacklistCosts,
            InvoiceByHourcodeCosts:this.settings.InvoiceByHourcodeCosts,
            AddCreditInvoiceCosts:this.settings.AddCreditInvoiceCosts,
            PMHistoryCosts:this.settings.PMHistoryCosts,
            NewChatCosts:this.settings.NewChatCosts,
            SendInvoiceOnCheckedCosts:this.settings.SendInvoiceOnCheckedCosts,
            SendInvoiceSaleOnCheckedCosts:this.settings.SendInvoiceSaleOnCheckedCosts,
            EnableHourPlanningCosts:this.settings.EnableHourPlanningCosts,
            EnableVacationPlanningCosts:this.settings.EnableVacationPlanningCosts,
            EnableUsageInvoicesCosts:this.settings.EnableUsageInvoicesCosts,
            EnableEmployeesCosts:this.settings.EnableEmployeesCosts,
            EnableNewsletterCosts:this.settings.EnableNewsletterCosts,
            RegisterWorkhoursCosts:this.settings.RegisterWorkhoursCosts,
            SignWorkhoursCosts:this.settings.SignWorkhoursCosts,
            EnableWorkTimerCosts:this.settings.EnableWorkTimerCosts

          },
        )
      } catch (err) {
        console.log(err)
      }
    },


  },
}
    
</script>