<template>
    <div class="personal-settings">
        <div class="form">
            
            <div class="card">
                <div class="card-header" id="select_fonts" v-b-toggle="'collapse_select_fonts'">
                    Algemeen
                </div>
                <b-collapse id="collapse_select_fonts">
                    <div class="card-body">
                        <div class="form-row">
                            <div class="col-3">
                                <label for="font">Lettertype</label>
                            </div>
                            <div class="col-3">
                                <select id="font" v-model="font" class="form-control">
                                    <option v-bind:value="f" v-for="f in fonts" v-bind:key="f">{{f}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-3">
                                <label for="fontweight">Letterdikte</label>
                            </div>
                            <div class="col-3">
                                <select id="fontweight" v-model="font_weight" class="form-control">
                                    <option v-bind:value="'fw_'+i+'00'" v-for="i in 9" v-bind:key="i">{{i}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-row" v-if="createAny('settings')">
                            <div class="col-3">
                                <label for="font">Aantal weken vooruit in werkplek-popup</label>
                            </div>
                            <div class="col-3">
                                <input type="number" step="1" v-model="settings.workplace_overview_weeks" class="form-control" @change="save_settings" />
                            </div>
                        </div>
                    </div>
                </b-collapse>
            </div>
        </div>

        <div class="form">
            <div class="card">
                <div class="card-header" id="select_users_to_show" v-b-toggle="'collapse_select_users_to_show'">
                    Selecteer zichtbare gebruikers
                </div>
                <b-collapse id="collapse_select_users_to_show">
                    <div class="card-body">
                        <p>Selecteer hier de gebruikers die jij zichtbaar wil hebben. Dit is een persoonlijke instelling en heeft geen invloed op andere gebruikers.</p>
                        <div class="row">
                            <template v-for="(user) in user_visibility">
                                <div class="col-4" v-if="user.showplanning" :key="'visible-user-'+user.id">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" v-bind:id="'user-visible-checkbox-'+user.id" :value="user.id" :checked="user.checked" @change="toggle_user_visiblity($event, user.id)">
                                        <label class="custom-control-label" v-bind:for="'user-visible-checkbox-'+user.id">{{user.label}}</label>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </b-collapse>
            </div>
        </div>

        <div class="form">
            <div class="card">
                <div class="card-header" id="select_users_to_show" v-b-toggle="'collapse_select_workplaces_to_show'">
                    Selecteer zichtbare werkplekken
                </div>
                <b-collapse id="collapse_select_workplaces_to_show">
                    <div class="card-body">
                        <p>Selecteer hier de werkplekken die jij zichtbaar wil hebben. Dit is een persoonlijke instelling en heeft geen invloed op andere gebruikers.</p>
                        <div class="row">
                            <template v-for="(workplace) in workplace_visibility">
                                <div class="col-4" :key="'visible-workplace-'+workplace.id">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" v-bind:id="'workplace-visible-checkbox-'+workplace.id" :value="workplace.id" :checked="workplace.checked" @change="toggle_workplace_visiblity($event, workplace.id)">
                                        <label class="custom-control-label" v-bind:for="'workplace-visible-checkbox-'+workplace.id">{{workplace.label}}</label>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </b-collapse>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import Vue from 'vue'

export default {
    name: 'personal-settings-screen',
    data() {
        return {
        }
    },
    computed: {
        font: {
            get: function () {
                return this.user_by_id(this.current_user.id).font || "source_sans_pro"
            },
            set: function(val) {
                Vue.set(this.user_by_id(this.current_user.id), "font", val)
                this.$store.dispatch("save_user", this.current_user.id)
            }
        },
        font_weight: {
            get: function () {
                return this.user_by_id(this.current_user.id).font_weight || "fw_600"
            },
            set: function(val) {
                Vue.set(this.user_by_id(this.current_user.id), "font_weight", val)
                this.$store.dispatch("save_user", this.current_user.id)
            }
        },
        ...mapGetters([
            'createAny',
            'readAny',
            'updateAny',
            'deleteAny',

            'user_by_id'
        ]),
        ...mapState([
            'users',
            'workplaces',
            'user_planning',
            'workplace_comments',
            'settings',
            'current_user',
            'fonts'
        ]),
        user_visibility() {
            var current_user = this.user_by_id(this.current_user.id)
            return this.users.filter(user => user.archived != 1).map((user) => {
                return {
                    id: user.id,
                    showplanning: user.showplanning,
                    label: user.firstname + ' ' + user.middlename + ' ' + user.lastname,
                    checked: current_user.visible_users == undefined ? true : JSON.parse(current_user.visible_users).indexOf(user.id) > -1,
                }
            })
        },
        workplace_visibility() {
            var current_user = this.user_by_id(this.current_user.id)
            return this.workplaces.filter(workplace => workplace.archived != 1).map((workplace) => {
                return {
                    id: workplace.id,
                    label: workplace.name,
                    checked: current_user.visible_workplaces == undefined ? true : JSON.parse(current_user.visible_workplaces).indexOf(workplace.id) > -1,
                }
            })
        }
    },
    methods:{
        ...mapActions([
            'save_settings'
        ]),
        toggle_user_visiblity(event, user_id) {
            var current_user = this.user_by_id(this.current_user.id)

            var visible_users = []
            if (current_user.visible_users == undefined) {
                visible_users = this.users.map((user) => { return user.id })
            } else {
                visible_users = JSON.parse(current_user.visible_users)
            }

            if (visible_users.indexOf(user_id) > -1) {
                visible_users.splice(visible_users.indexOf(user_id), 1)
            } else {
                visible_users.push(user_id)
            }

            Vue.set(this.user_by_id(this.current_user.id), "visible_users", JSON.stringify(visible_users))
            this.$store.dispatch("save_user", this.current_user.id)
        },
        toggle_workplace_visiblity(event, workplace_id) {
            var current_user = this.user_by_id(this.current_user.id)

            var visible_workplaces = []
            if (current_user.visible_workplaces == undefined) {
                visible_workplaces = this.workplaces.map((workplace) => { return workplace.id })
            } else {
                visible_workplaces = JSON.parse(current_user.visible_workplaces)
            }

            if (visible_workplaces.indexOf(workplace_id) > -1) {
                visible_workplaces.splice(visible_workplaces.indexOf(workplace_id), 1)
            } else {
                visible_workplaces.push(workplace_id)
            }

            Vue.set(this.user_by_id(this.current_user.id), "visible_workplaces", JSON.stringify(visible_workplaces))
            this.$store.dispatch("save_user", this.current_user.id)
        }
    }
}
</script>