<template>
  <div class="login-form col-6 mt-4">
    <h1>Login</h1>
    <b-form @submit="onSubmit" @reset="onReset">
      <b-form-group id="usernamegroup" label="Gebruikersnaam" label-for="username" description="">
        <b-form-input id="username" type="text" v-model="form.username" required placeholder="Vul je gebruikersnaam in..."></b-form-input>
      </b-form-group>

      <b-form-group id="passwordgroup" label="Wachtwoord" label-for="password" description="">
        <b-form-input id="password" type="password" v-model="form.password" required placeholder="Vul je wachtwoord in..." autocomplete="plantool-password"></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary" class="mr-1" >Submit</b-button>
      <b-button type="reset" variant="danger" >Reset</b-button>

      <div class="mt-3">
        <b-alert :variant="messageVariant" :show="showMessage">{{message}}</b-alert>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
// import axios from 'axios';
export default {
  name: 'login',
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      message: '',
      messageVariant: 'info',
      showMessage: false
    }
  },
  computed: {
  },
  methods: {
    ...mapActions([
      'login'
    ]),

    onSubmit (evt) {
      evt.preventDefault();
      console.log('login')

      this.message = 'loading';
      this.messageVariant = 'info';
      this.showMessage = true;

      this.login(this.form)
    },
    onReset (evt) {
      evt.preventDefault();
      
      this.message = '';
      this.messageVariant = 'info';
      this.showMessage = false;

      /* Reset our form values */
      this.form.username = '';
      this.form.password = '';

      /* Trick to reset/clear native browser form validation state */
      // this.show = false;
      // this.$nextTick(() => { this.show = true });
    }
  },
  components: {},
  mounted() {}
}
</script>
