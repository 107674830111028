<template>
  <b-container>

    <!-- Title bar -->
    <div class="title-bar">
      <h1> {{ $t("labels.settings") }}</h1>
    </div>
    <b-row>
      <b-col>
        <h2>{{ $t("meta.administratie") }}</h2>
        <b-button-group vertical>
          <b-button active-class="active" v-if="true && current_user.role == 'admin'" to="/invoicesettings">
            <font-awesome-icon icon="euro-sign" /> Factuur instellingen
          </b-button>

          <b-button active-class="active" v-if="true && current_user.role == 'admin'" to="/modulecosts">
            <font-awesome-icon icon="puzzle-piece" /> {{ $t("buttons.modules") }} kosten
          </b-button>
          <b-button active-class="active" v-if="true && current_user.role == 'admin'" to="/emailsettings">
            <font-awesome-icon icon="envelope" /> {{ $t("buttons.emailtemplates") }}
          </b-button>
          <b-button active-class="active" v-if="true && current_user.role == 'admin'" to="/emailsenders">
            <font-awesome-icon icon="envelope" /> {{ $t("buttons.emailsenders") }}
          </b-button>
          <b-button active-class="active" v-if="true && current_user.role == 'admin'" to="/cronsettings">
            <font-awesome-icon icon="spider" /> {{ $t("buttons.cronsettings") }}
          </b-button>   
        </b-button-group>

      </b-col>
    </b-row>
    <hr>
    <div class="form-row">
      <div class="col-3"><label for="">Huidige app release build</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.AppRelease"/></div>
      <div class="col-3">
      <div @click="save_settings" class="btn btn-primary">
        <font-awesome-icon icon="save" /> {{ $t("buttons.savesettings") }}
      </div>           </div>
    </div>

    <br>
    <!-- <div v-if="createAny('project')" @click="css()" class="btn btn-primary">
      <font-awesome-icon icon="plus" />css
    </div> -->
    <!-- <div v-if="createAny('project')" @click="sendWhatsapp()" class="btn btn-primary">
      <font-awesome-icon icon="plus" />whatsapp
    </div> -->
     <!-- <h1> IMPORT ZZPers </h1>
    <textarea style="width:100%; height:300px" v-model="importdata" />

    <div v-if="createAny('project')" @click="parseimportZZPR()" class="btn btn-primary">
      <font-awesome-icon icon="plus" />importusers
    </div> -->
    <!-- 
    <h1> IMPORT users </h1>
    <textarea style="width:100%; height:300px" v-model="importdata" />

    <div v-if="createAny('project')" @click="parseimportUsers()" class="btn btn-primary">
      <font-awesome-icon icon="plus" />importusers
    </div> -->

    <!-- <h1> IMPORT companies </h1>
    <textarea style="width:100%; height:300px" v-model="importdata" />

    <div v-if="createAny('project')" @click="parseimportCompany()" class="btn btn-primary">
      <font-awesome-icon icon="plus" />importcompany
    </div> -->
    <div>

    </div>
  </b-container>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapState, mapActions } from 'vuex'


export default {
  name: 'navigation',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'createAny',
    ]),
    ...mapState([
      'current_user',
      'users',
      'settings'
    ]),

  },
  methods: {
    ...mapActions([
    ]),

    save_settings() {
      try {
        axios.patch('/api/settings/',
          {
            AppRelease: this.settings.AppRelease

          },
        )
      } catch (err) {
        console.log(err)
      }
    }, 
    test (){

    },



  },
  components: {},
}
</script>