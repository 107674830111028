<template>
    <b-container>
        <b-row>
            <b-col>
                <router-link to="/portals" class="list-group-item list-group-item-action"><font-awesome-icon icon="hammer" /> Portals</router-link>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <router-link to="/invoices" class="list-group-item list-group-item-action"><font-awesome-icon icon="euro-sign" /> Invoices</router-link>
            </b-col>     
        </b-row>
        <b-row>                   
            <b-col>
                <router-link to="/settings" class="list-group-item list-group-item-action"><font-awesome-icon icon="cog" /> Instellingen</router-link>
            </b-col>            
        </b-row>
        <b-row>
            <b-col>
                <a @click="logout" class="list-group-item list-group-item-action"><font-awesome-icon icon="sign-out-alt" /> Uitloggen</a>
            </b-col>
        </b-row>
    </b-container>
</template>        

<script>

import { mapGetters, mapState, mapActions } from 'vuex'


import settings_modal from './modals/SettingsModal'
import search_modal from './modals/SearchModal'

export default {
    name: 'navigation',
    data() {
        return {
            jumpdate: ""
        }
    },
    computed: {
    ...mapGetters([
      'createAny',
      'readAny',
      'updateAny',
      'deleteAny',
    ]),
    ...mapState([
    ])
  },
    methods: {
        ...mapActions([
            'logout',
        ]),

        show_settings_modal() {
            this.$modal.hide('settings-modal')
            this.$modal.show(settings_modal, {}, {
                name: 'settings-modal',
                draggable: '.popup-header',
                height: 'auto',
                width: '900px'
            })
        },
        show_search_modal() {
            this.$modal.hide('search-modal')
            this.$modal.show(search_modal, {}, {
                name: 'search-modal',
                draggable: '.popup-header',
                height: 'auto',
                width: '900px'
            })
        },
    },
    components: {},
    created: function() {
        
    }
}
</script>