<template>
  <b-container>
    <b-button @click="edit_portal(current_portal)" title="Edit">
      <font-awesome-icon icon="edit" />
    </b-button>    
    <b-button @click="onPortalClicked(current_portal)" title="Modules">
      <font-awesome-icon icon="list" />
    </b-button>
    <b-button @click="portalinvoice(current_portal)" title="Invoice">
      <font-awesome-icon icon="file-invoice" />
    </b-button>
    <b-button @click="portalstats(current_portal)" title="Statistics">
      <font-awesome-icon icon="chart-line" />
    </b-button>  
    <b-button @click="portalrestart(current_portal)" title="Restart">
      <font-awesome-icon icon="power-off" />
    </b-button>      
    <b-button @click="portallogin(current_portal)" title="Login">
      <font-awesome-icon icon="sign-in-alt" />
    </b-button>      
  </b-container>   
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'portalnavigations',
  data() {
      return {
      }
  },
  computed: {
      ...mapGetters([
          'current_portal'
      ]),
      ...mapState([
      ]),
  },
}
</script>