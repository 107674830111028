<template>
  <b-container v-if="createAny('users')" fluid>

    <!-- TItle bar -->
    <div class="title-bar">
      <h1>{{$t('buttons.emailtemplates')}}</h1>
    </div>
    <!-- Hours filled -->
    <h2 class="mt-5">Begeleidende Email gebruiksfacturen </h2>
    <p>Gebruik [week], [portal] op de plek waar de week wilt invullen</p>
    <b-form-group :label="$t('templates.subject')">
      <b-form-input type="text" class="form-control" v-model="settings.invoicemailsubject" />
    </b-form-group>
    <b-form-group :label="$t('templates.message')">
      <ckeditor :editor="editor" v-model="settings.invoicemailmessage" :config="editorConfig"></ckeditor>
    </b-form-group>
    <!-- Save button -->
    <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="plus" /> {{ $t("buttons.savesettings") }}
    </div>

    <!-- footer  -->
    <h2 class="mt-5">{{$t('titles.emailheader')}}</h2>
    <div >
      <b-form-textarea rows="8" type="text" class="form-control" v-model="settings.generalheader" />
    </div>  
    <!-- Save button -->
    <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="plus" /> {{ $t("buttons.savesettings") }}
    </div> 
    <!-- email header -->
    <h2 class="mt-5">{{$t('titles.emailheaderimage')}}</h2>
    <div class="row">
      <div class="col">
        <img style="width:100%" src="/api/media_main_documents/ALG_Main_header.png"/>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-3">
            <label for=""> {{ $t("labels.file") }}</label>
          </div>
          <div class="col">
            <b-form-file ref="file-input" class="form-control" accept="*/*" v-model="file1"  />
          </div>
        </div> 
      </div>
      <div class="row">
        <div class="col-3">
          <div v-if="file1" class="btn btn-primary" @click="my_add_document();">{{ $t("buttons.replace") }}</div>
        </div>
      </div>
    </div>
    <!-- Email signature -->
    <h2 class="mt-5">{{$t('titles.signature')}}</h2>
    <div id="app">
      <ckeditor :editor="editor" v-model="settings.signature" :config="editorConfig"></ckeditor>
    </div>    
    <!-- Save button -->
    <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="plus" /> {{ $t("buttons.savesettings") }}
    </div>    
    <!-- footer  -->
    <h2 class="mt-5">{{$t('titles.emailfooter')}}</h2>
    <div >
      <b-form-textarea rows="8" type="text" class="form-control" v-model="settings.generalfooter" />
    </div>  
    <!-- Save button -->
    <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="plus" /> {{ $t("buttons.savesettings") }}
    </div>     
    <b-form-group label="Verstuur test E-mail naar">
      <b-form-input type="text" class="form-control" v-model="testemail" />
    </b-form-group>      
     <div @click="test" class="btn btn-primary">
      <font-awesome-icon icon="envelope" />  Test E-mail
    </div>     

  </b-container>


</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import axios from 'axios'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: 'settings-screen',
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items:[ 'heading', '|', 'undo', 'redo', '-', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ,'insertTable'],
          shouldNotGroupWhenFull: true
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
          ]
        },
        table: {
            contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        }
      },
      subject: ",mn,ndn nfklajljflaj kls",
      selected: [],
      file1:null,
      testemail:""

    }
  },

  computed: {

    ...mapState([
      'settings',
      'users',
      'contracts',
      'hours'

    ]),
    ...mapGetters([
      'server_url',
      'username_by_id',
      'user_by_id',
      'project_by_id',
      'contract_by_id',
      'company_by_id',
      'factuur_by_id',
      'confirm_modal',
      'planning_by_id',
      'createAny'
    ]),
  },
  methods: {
    ...mapActions([
    ]),
    my_add_document(){
        const formData = new FormData();
        formData.append('file', this.file1)
        try{
          axios.post('/api/emailheader/', formData)  .then((response) => {
              console.log('succes',response)
          })
        } catch(err){
          console.log (err)
        }
      },    
    save_settings() {
      try {
        axios.patch('/api/settings/',
          {
            signature: this.settings.signature,
            generalheader: this.settings.generalheader.replace(/\n|\r/g, ""),
            generalfooter: this.settings.generalfooter.replace(/\n|\r/g, ""),
            invoicemailmessage:this.settings.invoicemailmessage,
            invoicemailsubject:this.settings.invoicemailsubject,
            
          },
        )
          .then((response) => {
            console.log(response)
          })
      } catch (err) {
        console.log(err)
      }
    },
    test(){
      let message = {}
            message.email = this.testemail
            message.name = "test"
            message.message = "<h1>testbericht</h1><p> testbericht vanaf de FlyingFreelancers Admin portal</p>"
            message.subject = "Testbericht"
            message.maindocuments = []
            message.sender = -1
            message.sendermail = -1
            message.sendername =  -1      
            axios.post('/api/msal/contact_user', message
            ) 

    }
  },

}
</script>