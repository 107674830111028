<template>
	<div class="popup">

        <div class="popup-header">
            <div v-if="user.id > 0">
                {{user.firstname}} {{user.middlename}} {{user.lastname}}
            </div>
            <div v-else>
                Nieuwe gebruiker
            </div>
            <div v-if="user.id == 0" class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
            <div v-else class="popup-close" @click="save_and_close">
                <font-awesome-icon icon="times" />
            </div>
        </div>

        <div class="popup-body">
            <form class="form">
                <!-- Username -->
                <div class="form-row">
                    <div class="col-3">
                        <label for="">Gebruikersnaam</label>
                    </div>
                    <div class="col">
                        <input type="text" class="form-control" v-model="user.username">
                    </div>
                </div>
                
                <!-- Password -->
                <div class="form-row">
                    <div class="col-3">
                        <label for="">Wachtwoord</label>
                    </div>
                    <div class="col">
                        <input type="password" class="form-control" v-model="user.password" placeholder="">
                    </div>
                </div>

                <!-- Role -->
                <div class="form-row">
                    <div class="col-3">
                        <label for="">Rol</label>
                    </div>
                    <div class="col">
                        <select v-model="user.role" class="form-control">
                            <option value="user">ZZPR</option>
                            <option value="planner">Contactpersoon</option>
                            <option value="hoofdplanner">Hoofdplanner</option>
                            <option value="admin">Admin</option>
                        </select>
                    </div>
                </div>
                <!-- dynamic ordermetas -->
                <div v-for="meta in user_metas" v-bind:key="meta.key">
                    <div class="form-row" v-if="user[meta.toggle] || !meta.toggle">
                    <div class="col-3">
                        <label v-bind:for="meta.key">{{meta.label}}</label>
                    </div>
                    <div class="col" v-bind:class="{'col-4': meta.type == 'date', 'col-2': meta.type == 'number'}">
                        <input v-bind:type="meta.type" v-bind:placeholder="meta.label" v-bind:id="meta.key" class="form-control" v-model="user[meta.key]" @change="darken_color(meta)">
                    </div>
                    </div>
                </div>                

            </form>
        </div>

        <div class="popup-footer">
            <div v-if="user.id == 0" class="btn btn-primary" @click="add_user(user); $emit('close')">Toevoegen</div>
            <div v-else @click="save_and_close" class="btn btn-primary">Opslaan & Sluiten</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
    name: 'user-modal',
    data() {
        return {
            new_user: {role: 'user', id: 0, color1: '#ccaaaa', color2: '#cc1111', font: 'source_sans_pro', font_weight: 'fw_600' }
        }
    },
    props: {
        user_id: Number
    },
    mounted() {
        this.blocked_hours = this.user.blocked_hours ? JSON.parse(this.user.blocked_hours) : []
    },
    // watch: {
    //     blocked_hours: {
    //         deep: true,
    //         handler() {
                
    //         }
    //     }
    // },
    computed: {
        user() {
            console.log('modal',this.user_id)
            return this.user_id > 0 ? this.user_by_id(this.user_id) : this.new_user
        },
        ...mapGetters([
            'user_metas',
            'user_by_id',

        ]),
        ...mapState([
            'users',
            'settings',
            'current_user',
        ])
    },
    methods: {
        save_and_close() {
            this.save_user(this.user.id)
            this.$emit('close')
        },
        ...mapActions([
            'save_user',
            'add_user'
        ]),

    }
}
</script>