
import Vue from 'vue'
import './plugins/axios'
import './plugins/bootstrap-vue'
import App from './App.vue'
import store from './store'
import router from './router'
import CKEditor from '@ckeditor/ckeditor5-vue2'

// import './scss/main.scss'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fas)
library.add(far)

Vue.component('font-awesome-icon', FontAwesomeIcon)
import i18n from '@/plugins/i18n';
import '@/mixins/generalMixin';


import VModal from 'vue-js-modal'
Vue.use(VModal, { dynamic: true, dynamicDefaults: { clickToClose: false } })
Vue.use(CKEditor)

import message_modal from './components/modals/MessageModal'
require('./scss/zzpr.scss');

//SET AXIOS DEFAULTS
  import axios from 'axios';
  axios.defaults.withCredentials = true;
  
  Vue.filter('yesno', function (value) {
    return value ? 'Ja' : 'Nee';
  })
  
//CHECK IF STILL LOGGED IN
axios.interceptors.request.use(
    //success handler
    function (config) {
      store.commit('loading', true)
      return config;
    }, 
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  )
  axios.interceptors.response.use(
    //success handler
    function (response) {
      store.commit('loading', false)
      return response;
    }, 
    
    //error handler
    function (error) {
      if (error.response) {
        if (error.response.status == 401) {
          // store.commit('current_user', null)
          // store.dispatch('get_current_user')
        }
        else 
        {
          root.$modal.show(message_modal, {
                      title: error.response.data,
                      message: error.response.data
                  }, {
                      draggable: '.popup-header',
                      height: 'auto',
                      width: '600px'
                  })
        }
      }
  
      // Do something with response error
      return Promise.reject(error);
    }
  )
 //START VUE
var root = new Vue({
    store,
    router,
    i18n, 
    render: h => h(App),
  }).$mount('#app')
  
  
