<template>
  <b-container v-if="createAny('users')" fluid>
    <h1>{{$t('buttons.cronsettings')}}</h1>

    <h3>{{$t('labels.sendinvoices')}}</h3>
    <b-form-checkbox  v-model="settings.cronsendinvoices">
            {{$t('labels.statusactive')}}
    </b-form-checkbox>
    <b-form-group :label="$t('labels.crontime')">
      <b-form-input type="text" class="form-control" v-model="settings.crontimesendinvoices" />
    </b-form-group>    
    <!-- Save button -->
    <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="plus" /> {{ $t("buttons.savesettings") }}
    </div>
  </b-container>
</template>
<script>

import axios from 'axios'
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'emailsenders',
  data() {
    return {
      authurl:"",

    }
  },
  mounted() {
      fetch('/api/msal/authurl')
      .then((response) => response.json())
      .then((data) => this.authurl = data)      
      console.log(">>>", this.authurl)
    },   
  computed: {
    ...mapGetters([
      'createAny',
    ]),
    ...mapState([
      'current_user',
      'settings'

    ]),
    ip(){
      return location.host
    }
  },
  methods: {
    ...mapActions([
    ]),
    save_settings() {
      try {
        axios.patch('/api/settings/',
          {
            crontimesendinvoices: this.settings.crontimesendinvoices,
            cronsendinvoices: this.settings.cronsendinvoices,

          },
        )
          .then((response) => {
            console.log(response)
            axios.get('/api/cronset/');
          })
      } catch (err) {
        console.log(err)
      }
    },  
    authenticate(){
      window.open(this.authurl);
    }       

  },
  components: {},
}
</script>