<template>
  <b-container>
      <b-row>
          <b-col cols="8">
              <h1>Portals</h1>
          </b-col>
          <b-col>
              <div @click="edit_portal({ id: 0 })" class="btn btn-primary"><font-awesome-icon icon="plus" /> Add Portal</div>
          </b-col>
      </b-row>
      <b-table :items="portals" :fields="fields" @row-clicked="onPortalClicked">

        <template #cell(available)="row">
          <font-awesome-icon icon="circle" v-if="row.item.available==true" color="green"/> 
          <font-awesome-icon icon="circle" v-else color="red"/> 
        </template>
      
        <template #cell(actions)="row">
          <b-button @click="edit_portal(row.item)" title="Edit">
              <font-awesome-icon icon="edit" />
          </b-button>
          <b-button @click="onPortalClicked(row.item)" title="Modules">
              <font-awesome-icon icon="list" />
          </b-button>
          <b-button @click="portalinvoice(row.item)" title="Invoice">
              <font-awesome-icon icon="file-invoice" />
          </b-button>
          <b-button @click="portalstats(row.item)" title="Statistics">
              <font-awesome-icon icon="chart-line" />
          </b-button>
          <b-button @click="portalrestart(row.item)" title="Restart">
            <font-awesome-icon icon="power-off" />
          </b-button>                
          <b-button @click="portallogin(row.item)" title="Login">
              <font-awesome-icon icon="sign-in-alt" />
          </b-button>                    
          <b-button @click="delete_portal(row.item.id)" title="Delete">
              <font-awesome-icon icon="trash" />
          </b-button>          
        </template>
      </b-table>
  </b-container>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
// import portal_details_modal from './modals/PortalModal.vue'
import axios from 'axios'

export default {
  name: 'portals',
  data() {
      return {
        interval:null,
        refresh:0
      }
  },
  computed: {

      ...mapGetters([
          'createAny',
          'readAny',
          'updateAny',
          'deleteAny',
          'current_portal'
      ]),
      ...mapState([
          'portals',
          'settings'
      ]),
      fields() {
      return [
        { key: 'name', label: "Name", sortable: true, sortDirection: 'desc' },
        { key: 'available', label: "Status" },
        { key: 'actions', label: "Actions" },
      ]
    },      
  },
  mounted(){
    var vm = this
     this.interval = setInterval(async function() {
        for(let x in vm.portals){
          await axios.get(vm.portals[x].url+"api/server_status")
          .then(({data}) => {
            vm.portals[x].available = data
          })
          .catch((err) => {console.error(err)
            vm.portals[x].available = false
          });
          
        }
        vm.refresh++
    }, 5000);    
  },
  destroyed() {
    clearInterval(this.interval);
    console.log("destroyed")
  },  

  methods:{
      ...mapActions([
          'delete_portal',
          'save_portal'
      ]),
      // edit_portal(portal) {
      //   console.log(portal)
      //     this.$modal.hide('portal-details-modal')
      //     this.$modal.show(portal_details_modal, {
      //         portal_id: portal.id,
      //     }, {
      //         name: 'portal-details-modal',
      //         height: 'auto',
      //         width: '800px'
      //     })

      //     this.$emit('close')
      // },
  },
}
</script>
