<template>
  <b-container fluid>
    <h1>Portal Invoice {{ portal.name }}</h1>
    <PortalNavigations />

    <div class="form-row">
        <div class="col-3">
            <label for="">Week</label>
        </div>
        <div class="col">
            <input type="text" class="form-control" v-model="week">
        </div>
    </div>
    <div class="form-row">
        <div class="col-3">
            <label for="">{{$t('labels.numberofweeks')}}</label>
        </div>
        <div class="col">
            <input type="text" class="form-control" v-model="numberofweeks">
        </div>
    </div>    
    <div class="form-row">
        <div class="col-3">
            <label for="">year</label>
        </div>
        <div class="col">
            <input type="text" class="form-control" v-model="year">
        </div>
    </div>    
    <div class="form-row">
        <div class="col-3">
            <label for="">invoicenumber</label>
        </div>
        <div class="col">
            <input type="text" class="form-control" v-model="invoicenumber">
        </div>
    </div>
    <div class="form-row">
        <div class="col-3">
            <label for=""></label>
        </div>
        <div class="col">
          <b-form-checkbox  v-model="uitgebreid">Uitgebreide factuur</b-form-checkbox>
        </div>
    </div>
    <div class="form-row">
        <div class="col-3">
            <label for="">remarks</label>
        </div>
        <div class="col">
            <textarea class="form-control" v-model="remark"></textarea>
        </div>
    </div>     
    <div class="btn btn-primary" @click="generateinvoice();">Generate invoice</div>

  </b-container>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapState } from 'vuex'
import PortalNavigations from './PortalNavigations.vue';

export default {
  name: 'invoice-stats',
  data() {
    return {
      portal_id:null,
      startweek:null,
      year:null,
      invoicenumber:null,
      remark:"",
      uitgebreid:false,
      numberofweeks:1

    }
  },

  async mounted() {
     this.portal_id = Number(this.$route.params.id)
     this.$nextTick(async () => {
      await axios.get(this.portal.url+"api/usage")
     .then(({data}) => {
            this.stats = data
          })

      })
    

  },
  computed: {
    portal: {
      get() {
        return this.portal_by_id(this.$route.params.id)
      },
    },
  

    ...mapGetters([
      'portal_by_id',
    ]),
    ...mapState([
        'portals',
    ])
  },
  methods:{
    async generateinvoice(){
      const body = {
        portal: this.portal,
        factuurnummer:this.invoicenumber,
        startweek:this.week,
        numberofweeks:this.numberofweeks,
        year:this.year,
        omschrijving: this.remark,
        uitgebreid:this.uitgebreid
      }
      await axios.post('/api/ff_invoice', body)

      
      let routeUrl = "https://admin.flyingfreelancers.nl/api/media_invoices/ffinvoice-" + this.portal.debnum + '-' + this.invoicenumber+ ".pdf"
      setTimeout(function () {
        window.open(routeUrl, '_blank')
      }, 100);

    }

  },
  components:{PortalNavigations}

}
    
</script>