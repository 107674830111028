
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router'

const AccessControl = require('accesscontrol')

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,

    //SERVER SETTINGS
    api_protocol: 'http://',
    api_host: 'localhost',
    api_port: '12346',
    api_heartbeat: '10000',
    server_online: false,

    //LAYOUT OPTIONS
    show_users: true,
    show_workplaces: true,
    show_sidebar: true,

    //ROLES
    roles: null,
    //USER DATA
    current_user: null,
    settings: {},
    users: [],
    portals:[],
    current_portal:null,
    authemailsenders: [], 
    invoices:[]

  },
  getters: {
    //USER GETTERS
    is_logged_in: (state) => {
      return state.current_user != null;
    },
    user_index_by_user_id: (state) => {
      return state.users.reduce((map, item, index) => {
        map[item.id] = index
        return map
      }, {})
    },    
    user_by_id: (state, getters) => (id) => {
      return state.users[getters.user_index_by_user_id[id]]
    },  
    portal_index_by_portal_id: (state) => {
      return state.portals.reduce((map, item, index) => {
        map[item.id] = index
        return map
      }, {})
    },    
    portal_by_id: (state, getters) => (id) => {
      return state.portals[getters.portal_index_by_portal_id[id]]
    },  
    invoice_index_by_invoice_id: (state) => {
      return state.invoices.reduce((map, item, index) => {
        map[item.id] = index
        return map
      }, {})
    },    
    invoice_by_id: (state, getters) => (id) => {
      return state.invoices[getters.invoice_index_by_invoice_id[id]]
    },                          
    role:(state) => {
      return state.current_user.role
    },    
    createAny:(state, getters) => (resource) => {
      if (state.roles != null) {
        return state.roles.can(getters.role).createAny(resource).granted
      }
      return false;
    },
    readAny:(state, getters) => (resource) => {
      if (state.roles != null) {
        return state.roles.can(getters.role).readAny(resource).granted
      }
      return false;
    },
    updateAny:(state, getters) => (resource) => {
      if (state.roles != null) {
        return state.roles.can(getters.role).updateAny(resource).granted
      }
      return false;
    },
    deleteAny:(state, getters) => (resource) => {
      if (state.roles != null) {
        return state.roles.can(getters.role).deleteAny(resource).granted
      }
      return false;
    },
    //LAYOUT
    show_users: (state) => {
      return state.show_users
    },
    //METAS
    user_metas: (state) => {
      return state.settings.user_metas.filter((item) => item.type != 'checkbox');
    },
    current_portal:(state)=>{
      return state.current_portal
    }
  },
  mutations: {
    //SERVER MUTATIONS
    server_online(state, online) {
      state.server_online = online;
    },
    loading(state, loading) {
      state.loading = loading
    },    
    //USER MUTATIONS
    current_user(state, current_user) {
      state.current_user = current_user;
    },
    current_portal(state, current_portal) {
      state.current_portal = current_portal
    },
    users(state, users) {
      state.users = users
    },
    portals(state, portals) {
      state.portals = portals
    },    
    invoices(state, invoices) {
      state.invoices = invoices
    },        
    authemailsenders(state, authemailsenders) {
      state.authemailsenders = authemailsenders
    },
    //SETTINGS
    settings(state, newSettings) {
      Object.keys(newSettings).forEach(function(key) {
        var value = newSettings[key];
        Vue.set(state.settings, key, value);
      });
    },
    roles(state, roles) {
      state.roles = new AccessControl(roles)
    },    
  },  
  actions: {
    initialize({dispatch}) {
        dispatch('get_current_user')
        dispatch('get_settings')
        dispatch('get_users')
        dispatch('get_portals')
        dispatch('get_authemailsenders')
        dispatch('get_invoices')


    },    
    //authemailsenders
    get_authemailsenders({commit}) {
      axios.get("/api/msal/getusers")
      .then((response) =>{
        commit('authemailsenders', response.data)
      })
      .catch(() => {
      })
    },    
    //LOGGED IN USER ACTIONS
    get_current_user({state, commit}) {
      axios.get("/api/user").then((response) => {
        if (state.current_user == null || response.data.user.id != state.current_user.id) {
          commit('current_user', response.data.user);
        }
      })
      .catch(() => {
        commit('current_user', null);
      });
    },
    login({dispatch}, {username, password}) {
      axios.post('/api/login', { username: username, password: password})
      .then((response) => {
        if (response && response.data) {
          console.log("gonav")
          dispatch('initialize')
          router.push({ path: '/portals' });

        }
      })
    },
    logout({commit}) {
      axios.post('/api/logout').then((response) => {
        if (response && response.data) {
          commit('current_user', null)
        }
      })
    },
    //USERS DATA
    get_users({commit}) {
      //load all users and commit
      axios.get("/api/users/all")
      .then((response) => {
        commit('users', response.data)
      })
      .catch(() => {
      });
    },    
    add_user({state}, user) {
      axios.put("/api/user/"+user.id, user).then((response) => {
        Vue.set(user, 'id', response.data.id)
        state.users.push(user)
        // Vue.set(state.users, response.data.id, response.data)
      })
    },  
    save_user({getters}, user_id) {
      var user = getters.user_by_id(user_id)
      axios.put("/api/user/"+user_id, user)
    },
    delete_user({ getters }, user_id) {
      //get user and name
      var user = getters.user_by_id(user_id)
      var name = user.firstname
      if (user.middlename != undefined && user.middlename != '') {
        name = name + ' ' + user.middlename
      }
      name = name + ' ' + user.lastname

      //request confirm
      if (confirm('Weet je zeker dat je ' + name + ' wil verwijderen?')) {
        //post to backend
        axios.delete("/api/user/"+user_id)
        // .then(() => {
        //   //remove from users-list
        //   state.users.splice(user_id, 1)
        //   commit('users', state.users)
        // })
      }
    },     
    //PORTALS DATA
    get_portals({commit}) {
      //load all users and commit
      axios.get("/api/portals/all")
      .then((response) => {
        commit('portals', response.data)
      })
      .catch(() => {
      });
    },    
    add_portal({state}, portal) {
      axios.put("/api/portal/"+portal.id, portal).then((response) => {
        Vue.set(portal, 'id', response.data.id)
        state.portals.push(portal)
      })
    },  
    save_portal({getters}, portal_id) {
      var portal = getters.portal_by_id(portal_id)
      axios.put("/api/portal/"+portal_id, portal)
    },
    delete_portal({ getters }, portal_id) {
      //get user and name
      var portal = getters.portal_by_id(portal_id)
      var name = portal.name

      //request confirm
      if (confirm('Weet je zeker dat je ' + name + ' wil verwijderen?')) {
        //post to backend
        axios.delete("/api/portal/"+portal_id)
        // .then(() => {
        //   //remove from users-list
        //   state.users.splice(user_id, 1)
        //   commit('users', state.users)
        // })
      }
    },       
    //INVOICES
    get_invoices({commit}) {
      //load all users and commit
      axios.get("/api/invoices/all")
      .then((response) => {
        commit('invoices', response.data)
      })
      .catch(() => {
      });
    },    
    //SETTINGS
    get_settings({commit}) { 
      axios.get("/api/settings/")
      .then((response) => {
        commit('settings', response.data)
      })
      .catch(() => {
      })

      axios.get("/api/roles/")
      .then((response) => {
        commit('roles', response.data)
      })
      .catch(() => {
      })
    },
    save_settings({state, commit}) {
      axios.patch("/api/settings/", state.settings)
      .then((response) => {
        commit('settings', response.data)
      })
      .catch(() => {
      })
    },
  },
  modules: { 
  }
})
  