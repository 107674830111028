<template>
  <b-container v-if="createAny('users')" fluid>

    <!-- Title bar -->
    <div class="title-bar">
      <h1>{{ $t("buttons.emailsenders") }}</h1>
    </div>
    <p>
      {{ $t("validation.emailsenderexplain") }}
    </p>
    <b-button v-if="authurl" @click="authenticate">        <font-awesome-icon icon="plus" /> {{ $t("buttons.addemailsender") }}</b-button>
    <b-table :items="authemailsenders"/>
  
  </b-container>
</template>
<script>

import axios from 'axios'
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'emailsenders',
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      sortBy: 'id',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      selectedID: null,
      authurl:"",
      authenticatedUsers:[],
      addedAuthEmail:""

    }
  },
  mounted() {
      fetch('/api/msal/authurl')
      .then((response) => response.json())
      .then((data) => this.authurl = data)      
    },  
  computed: {
    ...mapGetters([
      'createAny',
      'confirm_modal',
      'emailsender_by_id'
    ]),
    ...mapState([
      'current_user',
      'emailsenders',
      'authemailsenders'
    ]),
    totalRows() {
      return this.emailsenders.length
    },
    fields() {
      return [
        { key: 'name', sortable: true, sortDirection: 'desc' },
        { key: 'email', sortable: true, sortDirection: 'desc' },
        { key: 'pulldown', label: this.$i18n.t('labels.actions') },
      ]
    },
  },
  methods: {
    ...mapActions([
      'save_emailsender',
      'add_emailsender',
      'delete_emailsender'
    ]),

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onEmailSenderClicked(row) {
      let emaillsenderclicked = this.emailsender_by_id(row.id)
      if (emaillsenderclicked.active == 1) {
        emaillsenderclicked.active = 0
      } else {
        emaillsenderclicked.active = 1
      }
      this.save_emailsender(row.id)
    },
    modalCloseAuth() {
      if (this.confirm_modal.id == 1) {
        axios.post('/api/msal/logout', { email : this.selectedID.email})
        .then(()=>{  
          this.$store.dispatch('get_authemailsenders')
        })
      }
    },
    authenticate(){
      window.open(this.authurl);
    }   

  },
  components: {},
}
</script>