// import axios from 'axios';
import Vue from 'vue'
import { mapGetters, mapState, mapActions } from 'vuex'
import portal_details_modal from '../components/modals/PortalModal.vue'
import axios from 'axios';

Vue.mixin({
  data() {
    return {
    };
  },
  created() {
  },
  computed: {
    ...mapGetters([
    ]),
    ...mapState([
    ]),
  },
  methods: {
    ...mapActions([
    ]),
    onPortalClicked(row) {
      this.$store.commit('current_portal', row)
      this.$router.push({ path: '/portal/' + row.id });
    },
    portalstats(row){
      this.$store.commit('current_portal', row)
      this.$router.push({ path: '/statistics/' + row.id });
    },
    portalinvoice(row){
      this.$store.commit('current_portal', row)
      this.$router.push({ path: '/invoice/' + row.id });
    },  
    portallogin(row){
      this.$store.commit('current_portal', row)
      console.log(row)
      this.newwin = window.open()//this.portal.url, "Axios data", "width=800,height=800");

      this.newwin.location.href = row.url
    },   
    portalrestart(row){
      console.log("restart", row)
      axios.post("/api/portal_reset", row)

    }, 
    
    edit_portal(portal) {
      console.log(portal)
        this.$modal.hide('portal-details-modal')
        this.$modal.show(portal_details_modal, {
            portal_id: portal.id,
        }, {
            name: 'portal-details-modal',
            height: 'auto',
            width: '800px'
        })

        this.$emit('close')
    },      
  }
})