import Vue from 'vue'
import Router from 'vue-router'
import Users from '@/components/Users'
import Contact from '@/components/Contact'
import Portals from '@/components/Portals'
import Portal from '@/components/Portal'
import Statistics from '@/components/Statistics'
import Invoice from '@/components/Invoice'
import Settings from '@/components/Settings'
import Cron from '@/components/Cron'
import Emailsenders from '@/components/EmailSenders'
import EmailSettings from '@/components/EmailSettings'
import ModuleCosts from '@/components/ModuleCosts' 
import InvoiceSettings from '@/components/InvoiceSettings'
import Invoices from '@/components/Invoices'
import GenerateInvoice from '@/components/GenerateInvoice'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/users/',
      name: 'Users',
      props: true,
      component: Users
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact
    },
    {
      path: '/portals',
      name: 'Portals',
      component: Portals
    },
    {
      path: '/settings',
      name: 'Settings',
      component: Settings
    },  
    {
      path:'/cronsettings',
      name: 'Cron',
      component:Cron
    },
    {
      path: '/emailsenders',
      name: 'Emailsenders',
      component: Emailsenders
    }, 
    {
      path:'/emailsettings',
      name: 'EmailSettings',
      component:EmailSettings
    },   
    {
      path:'/modulecosts',
      name: 'ModuleCosts',
      component:ModuleCosts
    },
    {
      path:'/invoicesettings',
      name: 'InvoiceSettings',
      component: InvoiceSettings

    },
    {
      path: '/portal/:id',
      name: 'Portal',
      component: Portal
    },
    {
      path: '/statistics/:id',
      name: 'Statistics',
      component: Statistics
    },
    {
      path: '/invoice/:id',
      name: 'Invoice',
      component: Invoice
    },
    {
      path: '/invoices',
      name:'invoices',
      component:Invoices
    },
    {
      path: '/geninvoice',
      name: 'GenInvoice',
      component: GenerateInvoice
    }
  ]
})