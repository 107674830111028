<template>
  <b-container v-if="createAny('users')" fluid>

    <!-- TItle bar -->
    <div class="title-bar">
      <h1>Factuur instellingen</h1>
    </div>
    <div class="form-row">
      <div class="col-3"><label for="">Factuurteller</label></div>
      <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.invoiceCounter"/></div>
    </div>
    <div class="form-row">
      <div class="col-3"><label for="">Omschrijving op factuur</label></div>
      <div class="col-9"><b-form-input type="text"   v-model="settings.invoiceDescription"/></div>
    </div>
    <!-- Save button -->
    <div @click="save_settings" class="btn btn-primary d-table ml-auto mt-5">
      <font-awesome-icon icon="plus" /> {{ $t("buttons.savesettings") }}
    </div>     
  </b-container>


</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'settings-screen',
  data() {
    return {
    }
  },

  computed: {

    ...mapState([
      'settings',
      'users',
      'contracts',
      'hours'

    ]),
    ...mapGetters([
      'server_url',
      'username_by_id',
      'user_by_id',
      'project_by_id',
      'contract_by_id',
      'company_by_id',
      'factuur_by_id',
      'confirm_modal',
      'planning_by_id',
      'createAny'
    ]),
  },
  methods: {
    ...mapActions([
    ]),
    my_add_document(){
        const formData = new FormData();
        formData.append('file', this.file1)
        try{
          axios.post('/api/emailheader/', formData)  .then((response) => {
              console.log('succes',response)
          })
        } catch(err){
          console.log (err)
        }
      },    
    save_settings() {
      try {
        axios.patch('/api/settings/',
          {
            invoiceCounter: this.settings.invoiceCounter,
            invoiceDescription: this.settings.invoiceDescription
          },
        )
          .then((response) => {
            console.log(response)
          })
      } catch (err) {
        console.log(err)
      }
    },
  },

}
</script>