<template>
  <b-container fluid>
    <h1>Generate Invoice</h1>
      <div class="form-row">
        <div class="col-3"><label for="">Factuurteller</label></div>
        <div class="col-3"><b-form-input type="number" step='0.01'  v-model="settings.invoiceCounter"/></div>
      </div>
      <div class="form-row">
        <div class="col-3"><label for="">Omschrijving op factuur</label></div>
        <div class="col-9"><b-form-input type="text"   v-model="settings.invoiceDescription"/></div>
      </div>    
        <b-form-group :label="$t('labels.startweek')">
          <b-form-input type="number" class="form-control" v-model="startweek" />
        </b-form-group>  
        <b-form-group :label="$t('labels.numberofweeks')">
          <b-form-input type="number" class="form-control" v-model="numberofweeks" />
        </b-form-group>
        <b-form-group label="Portal">
          <select  class="form-control admins" v-model="portal" >
            <option v-for="(p) in portals" :key="p.id" :value="p.id">{{p.name}}</option>
            </select>
        </b-form-group>
        <div @click="generate()" class="btn btn-primary">
          <font-awesome-icon icon="save" /> {{ $t("labels.generate") }}
        </div> 
    <!-- <input type="hidden" v-model="redraw"> -->
    </b-container>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapState} from 'vuex'

export default {
  name: 'navigation',
  data() {
    return {
      // description: "",
      startweek:0,
      numberofweeks:4,
      redraw:0,
      portal:null
    }
  },
  mounted(){
    // this.startweek = this.getWeekNumber(new Date())[1]
  },
  computed:{
    ...mapGetters([
      'project_by_id',
      'username_by_id',
      'zzpers',

    ]),
    ...mapState([
    'settings',
    'contracts',
    'planning', 
    'portals'

    ]),
    mondaystartweek(){
      let onejan = new Date(new Date().getFullYear(), 0, 1);
      var day = onejan.getDay(),
      diff = onejan.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      let monday = new Date(onejan.setDate(diff))
      monday.setDate(monday.getDate() + (7 * parseInt(this.startweek)));
      return monday
      // var mdd = String(monday.getDate()).padStart(2, '0');
      // var mmm = String(monday.getMonth() + 1).padStart(2, '0'); //January is 0!
      // var myyyy = monday.getFullYear();   
      // return mdd+"-"+mmm+"-"+myyyy   
    },  
    fridayendweek(){
      let endmonday = new Date(this.mondaystartweek)
      endmonday.setDate(endmonday.getDate()+(this.numberofweeks*7)-1)
      return endmonday
    } ,


  },
  methods:{
  async generate(){
      let p = this.portals.filter(p => p.id ==this.portal)
      console.log("new to me", p)

      // const body = {
      //   portal: p,
      //   factuurnummer: settings.invoiceCounter,
      //   startweek: thisweek,
      //   year: thisyear,
      //   omschrijving: settings.invoiceDescription,
      //   uitgebreid: this.uitgebreid
      // }
      await axios.get(p[0].url+'api/licenseinfo?start='+ this.startweek + '&numberweeks='+this.numberofweeks)
        .then(async (response) => {
          console.log(response.data)    
        }  )
    },
  }
}
</script>