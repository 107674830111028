<template>
	<div class="popup">

        <div class="popup-header">
            {{ title }}
            <div class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>

        <div class="popup-body">            
            <p>{{message}}</p>
        </div>
        <div class="popup-footer">
            <div @click="$emit('close')" class="btn btn-secondary">Sluiten</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'message-modal',
    props: {
        title: String,
        message: String
    }
}
</script>